<template>
  <div class="content">
    <div class="prod">
      <img
        class="tag"
        src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/sys_config/20220615_144902_62a980debc7c7.png"
        alt="标签"
      />
      <div class="qc">
        <qrcode-vue :value="qcCode" :size="200" level="H" />
      </div>
      <div class="info">
        <p>代理推广码 {{ user.phone }}</p>
      </div>
      <div class="discount">
        <p>分享人：{{ user.name }}</p>
        <p>联系方式：{{ user.phone }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'share',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      info: false,
      user: this.$state.user,
      qcCode: '',
    }
  },
  created() {
    this.$http('/agent/share').then((res) => {
      this.qcCode =
        'https://develop.zbt-merchant.422wsdq.com/#/register?id=' + res.data.id
    })
  },
}
</script>
<style lang="less" scoped>
.content {
  height: 100vh;
  padding: 32px;
  background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
  background: url(https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/sys_config/20220615_170024_62a99fa8e554b.png)
    no-repeat;
  background-size: 100%;
  p {
    margin: 0;
    padding-top: 3%;
  }
  .prod {
    background: #ffffff;
    height: 75%;
    position: relative;
    border-radius: 16px;
    .tag {
      width: 55.25px;
      height: 50.12px;
      padding: 8px;
    }
    .qc {
      text-align: center;
      margin-top: -20px;
    }
    .qcCode {
      width: 200px;
      height: 200px;
    }
    .info {
      text-align: center;
      font-size: 15px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
    }
    .discount {
      padding: 0 60px;
      padding-top: 50px;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      span {
        color: #f56f4d;
      }
    }
    .bg {
      width: 218px;
      height: 218px;
    }
  }
}
</style>
